import React from "react";

const About = () => {
  document.title = 'About'
  return (
    <div>
      <h2>Fun project to help you track real estate projects you love! </h2>
      <h5>
        Whenever you need a quick glimpse at the listing price or status change
        REtrack can help.
      </h5>
      <br />

      <h6>
        For questions & suggestions: <br></br>
        <strong>Email: info@retrack.lt</strong>
      </h6>
    </div>
  );
};

export default About;

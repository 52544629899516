import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Flat = () => {
  const [flat, setFlat] = useState({
    number: "",
    project_id: null,
    link: "",
    status: "",
    price: 0,
    price_per_sqm: 0,
    description: "",
    project: { name: "" },
    is_completed: false,
    versions: [{ date: "", changes: { price: [], status: [] } }],
  });

  const { id } = useParams();

  const formatCurrency = (n: number) => {
    return Intl.NumberFormat("lt-LT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(n);
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/flats/${id}`
      );
      setFlat(data);
      document.title = `${data.number} ${data.project.name}`;
    };
    getData();
  }, [id]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">
            {flat.number}
            <span
              className={
                "ms-3 badge bg-" +
                (flat.status === "available"
                  ? "success"
                  : flat.status === "reserved"
                  ? "warning"
                  : "danger")
              }
            >
              {flat.status}
            </span>
          </h2>
          <h3 className="card-subtitle">
            <a href={`/projects/${flat.project_id}`}> {flat.project.name}</a>
          </h3>
          <p className="card-text mt-3">
            {flat.price > 0 && (
              <>
                <strong> {formatCurrency(flat.price)}</strong>{" "}
                {flat.price_per_sqm > 0 && (
                  <span className="text-muted" title="Price per squere meter">
                    ( {formatCurrency(flat.price_per_sqm)} )
                  </span>
                )}
              </>
            )}
            <br />
            {flat.description}

            {flat.is_completed && (
              <span className="me-2 text-success" title="Is completed"></span>
            )}
          </p>
          {flat.link && (
            <a className="fs-5 card-link" href={flat.link}>
              {flat.link}
            </a>
          )}
        </div>
      </div>
      {flat.versions.length > 0 && (
        <>
          <h4>Changes</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th className="text-end">Price</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {flat.versions
                .slice(0)
                .reverse()
                .map((i, index) => (
                  <tr key={index}>
                    <td>{i.date}</td>
                    <td className="text-end">
                      {i.changes.price ? (
                        <>
                          {Intl.NumberFormat("lt-LT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                          }).format(i.changes.price?.[1])}
                          <br />
                          <small className="text-body-secondary">
                            {Intl.NumberFormat("lt-LT", {
                              style: "currency",
                              currency: "EUR",
                              minimumFractionDigits: 0,
                            }).format(i.changes.price?.[0])}{" "}
                          </small>
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-center">
                      {i.changes.status?.[1]}
                      <br />
                      <small>{i.changes.status?.[0]}</small>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default Flat;

import axios from "axios";
import { useEffect, useState } from "react";
import LoadingPlaceholder from "./LoadingPlaceholder";

const Projects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<
    {
      id: number;
      name: string;
      available: number;
      reserved: string;
      sold: string;
    }[]
  >([]);

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/projects?per_page=100`
    );
    setProjects(data.projects);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    document.title = "Projects";
  }, []);

  return (
    <>
      <h2>Projects</h2>
      {isLoading ? (
        <LoadingPlaceholder count={3} />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-end"></th>
              <th className="text-end">Available</th>
              <th className="text-end">Reserved</th>
              <th className="text-end">Sold</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((i) => (
              <tr key={i.id}>
                <td>
                  <a href={`/projects/${i.id}`}>{i.name}</a>
                </td>
                <td className="text-end">{i.available}</td>
                <td className="text-end">{i.reserved}</td>
                <td className="text-end">{i.sold}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Projects;

import axios from "axios";
import { useEffect, useState } from "react";
import Icon from "./Icon";
import InfiniteScroll from "react-infinite-scroller";
import LoadingPlaceholder from "./LoadingPlaceholder";
import LoadingSpinner from "./LoadingSpinner";

interface FlatsProps {
  project_id?: string;
}

const Flats = ({ project_id }: FlatsProps) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true)

  const [sort, setSort] = useState<{
    direction: string | null;
    field: string | null;
  }>({ direction: null, field: null });

  const [flats, setFlats] = useState<
    {
      id: number;
      description: string;
      price: number;
      price_per_sqm: number;
      number: string;
      status: string;
      versions_count: number;
      is_completed: boolean;
    }[]
  >([]);

  useEffect(() => {
    document.title = "Flats";
  }, []);

  useEffect(() => {
    setLoading(true);
    getData(true, 1);
  }, [sort]);

  const getInfiniteScroll = () => getData()

  const getData = async (reset_flats?:boolean, new_page?:number) => {
    
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/flats`,
      {
        params: {
          page: new_page || page,
          per_page: 100,
          project_id: project_id,
          sort_direction: sort.direction,
          sort_field: sort.field,
        },
      }
    );
    setLoading(false)
    setFlats(reset_flats ? data.flats : flats.concat(data.flats));
    setPage(data.meta.pagination.next);
    setHasMore(data.meta.pagination.total_pages > data.meta.pagination.current);
  };

  const handleSorting = (e: any, field: string) => {
    e.preventDefault();
    let new_sort = { ...sort };
    if (new_sort.field === field) {
      if (new_sort.direction === "desc") {
        new_sort.direction = "asc";
      } else {
        new_sort.direction = "desc";
      }
    } else {
      new_sort.direction = "asc";
    }
    new_sort.field = field;
    setSort(new_sort);
  };

  return (
    <>
      <h2>Flats</h2>
      {loading ? <LoadingSpinner /> :
      <InfiniteScroll
        loadMore={getInfiniteScroll}
        hasMore={hasMore}
        loader={
          <div key={0}>
            <LoadingPlaceholder count={5} />
          </div>
        }
      >
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {[
                { name: "Number" },
                { name: "Description" },
                { name: "Price per sqm", klass: "text-end" },
                { name: "Price", klass: "text-end" },
              ].map((i) => (
                <th key={i.name} className={i.klass}>
                  <a
                    href="#"
                    onClick={(e) =>
                      handleSorting(
                        e,
                        i.name
                          .split(" ")
                          .map((a) => a.toLowerCase())
                          .join("_")
                      )
                    }
                  >
                    {i.name}
                  </a>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {flats.map((i) => (
              <tr
                key={i.id}
                className={
                  i.status === "available"
                    ? ""
                    : i.status === "reserved"
                    ? "table-warning"
                    : "table-danger"
                }
              >
                <td>
                  <a href={`/flats/${i.id}`}>{i.number}</a>
                </td>
                <td>
                  {i.is_completed && (
                    <span className="me-2 text-success" title="Is completed">
                      <Icon name={"house-check-fill"} />
                    </span>
                  )}
                  {i.description}
                </td>
                <td className="text-end">
                  {Intl.NumberFormat("lt-LT", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                  }).format(i.price_per_sqm)}
                </td>
                <td className="text-end">
                  {Intl.NumberFormat("lt-LT", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                  }).format(i.price)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>}
    </>
  );
};

export default Flats;

import Navigation from "./Navigation";

const ErrorPage = () => {
  return (
    <>
      <Navigation />
      <div className="alert alert-danger">Page not found</div>
    </>
  );
};

export default ErrorPage;

import App from "../App";
import Flats from "./Flats";
import Projects from "./Projects";
import Versions from "./Versions";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "REtrack";
  }, []);

  return (
    <App>
      <div className="row">
        <div className="col-sm">
         <Projects />
        </div>
        <div className="col-sm">
        <Versions
            date={Intl.DateTimeFormat("lt-LT", {
              dateStyle: "short",
            }).format(new Date())}
            title="Today's updates"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <Flats />
        </div>
      </div>
    </App>
  );
}

export default Home;

const LoadingPlaceholder = ({ count = 2 }: { count?: number }) => {
  return (
    <>
      {Array.from(Array(count), (e, i) => {
        return (
          <div key={i} className="placeholder-glow mb-3">
            <span className="placeholder col-12 bg-secondary"></span>
          </div>
        );
      })}
    </>
  );
};

export default LoadingPlaceholder;

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Flats from "./Flats";
import Chart from "./Chart";

const Project = () => {
  const [project, setProject] = useState({
    name: "",
    link: "",
    available: 0,
    reserved: 0,
    sold: 0,
    versions: [
      { date: "", state: {available: 0, reserved: 0, sold: 0} },
    ],
  });

  const [chartData, setChartData] = useState([{}]);
  const [progressData, setProgressData] = useState([] as number[] );

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`
      );
      setProject(data);

      document.title = data.name;

      setProgressData([
        data.sold + data.reserved + data.available,
        data.available,
        data.reserved,
        data.sold,
      ]);

      setChartData(
        data.versions.map((i: any) => ({
          date: i.date,
          available: i.state.available,
          reserved: i.state.reserved,
          sold: i.state.sold
        }))
      );
    };
    getData();
  }, [id]);

  return (
    <>
      <div>
        <h2>{project.name}</h2>
      </div>
      { project.link && <p className="fs-5"><a href={project.link}>{project.link}</a></p> }

      <div className="row">
        <div className="col-sm">
          <div className="progress-stacked my-4" style={{ height: "30px" }}>
            {progressData.slice(1).map((i, index) => (
              <div
                key={index}
                className="progress"
                style={{ width: `${i/progressData[0] * 100}%`, height: "30px" }}
              >
                <div
                  className={
                    "progress-bar text-bg-" +
                    (index === 0
                      ? "success"
                      : index === 1
                      ? "warning"
                      : "danger")
                  }
                >
                  {i}
                </div>
              </div>
            ))}
          </div>

          <h4>Changes</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th className="text-center">Available</th>
                <th className="text-center">Reserved</th>
                <th className="text-center">Sold</th>
              </tr>
            </thead>
            <tbody>
              {project.versions
                .slice(0)
                .reverse()
                .map((i, index) => (
                  <tr key={index}>
                    <td>{i.date}</td>
                    <td className="text-center">{i.state.available}</td>
                    <td className="text-center">{i.state.reserved}</td>
                    <td className="text-center">{i.state.sold}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="col-sm">
          <Chart data={chartData} />
        </div>
      </div>
      <Flats project_id={id} />
    </>
  );
};

export default Project;

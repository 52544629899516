import { NavLink } from "react-router-dom";
import Icon from "./Icon";
import { useState, useEffect } from "react";

const Navigation = () => {

  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem("darkMode") || 'false'));

  const links = [
    // { name: "Home", link: "/", icon: "house-fill" },
    { name: "Projects", link: "/projects", icon: "" },
    { name: "Flats", link: "/flats" },
    { name: "Latest changes", link: "/versions" },
    { name: "About", link: "/about " },
  ];

  useEffect(() => {
    document.body.setAttribute("data-bs-theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleChange=()=>{

    localStorage.setItem("darkMode", JSON.stringify(!darkMode))

    setDarkMode(!darkMode)

  }

  return (
    <nav className="navbar navbar-expand-sm bg-body-tertiary mb-4 rounded">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <span className="fs-4">RE</span>
          <span className="fw-light fst-italic text-danger">track</span>
        </a>
        <button
          className="navbar-toggler border border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <Icon name="list" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="navbar-nav me-2">
            {links.map((i, index) => (
              <NavLink key={index} to={i.link} className="nav-link nav-item">
                {i.icon && <Icon name={i.icon} />} {i.name}
              </NavLink>
            ))}
            
          </div>

              <span className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={darkMode}
                  onChange={handleChange}
                />
                <label className="form-check-label">Dark mode</label>
              </span>

        </div>
      </div>
    </nav>
  );
};

export default Navigation;

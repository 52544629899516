import Navigation from "./components/Navigation";
import { useState, useEffect } from "react";

function App({ children }: { children: React.ReactNode }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <>
      {!isOnline && (
        <div className="alert alert-danger">
          You are offline. Please connect to internet!
        </div>
      )}
      <div className="row">
        <Navigation />
      </div>
      {children}
    </>
  );
}

export default App;

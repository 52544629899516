import axios from "axios";
import { useEffect, useState } from "react";
import LoadingPlaceholder from "./LoadingPlaceholder";

interface VersionsProps {
  date?: string;
  title?: string;
}

const Versions = ({ date, title = "Lastest Changes" }: VersionsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState<
    {
      id: number;
      date: string;
      item_type: string;
      item_id: number;
      item_name: string;
      //changes: { price: [], status: [], available: [], reserved: [], sold: [] };
    }[]
  >([]);

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/versions`,
      {
        params: {
          date: date,
        },
      }
    );
    setVersions(data["paper_trail/versions"]);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    document.title = "Latest changes";
  }, []);

  return (
    <>
      <h2>{title}</h2>
      {isLoading ? (
        <LoadingPlaceholder count={3} />
      ) : versions.length === 0 ? (
        <div className="alert alert-warning">
          There is nothing to display :(
        </div>
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {versions.map((i) => (
              <tr key={i.id}>
                <td>{i.date}</td>
                <td>
                  <a
                    href={`/${
                      i.item_type === "Project" ? "projects" : "flats"
                    }/${i.item_id}`}
                  >
                    {i.item_name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Versions;

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

interface ChartProps {
  data: Array<{ date?: string; available?: number; reserved?: number, sold?: number }>;
}

const Chart = ({ data }: ChartProps) => {
  return (
    <ResponsiveContainer height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="available" stroke="#198754" />
        <Line type="monotone" dataKey="reserved" stroke="#ffc107" />
        <Line type="monotone" dataKey="sold" stroke="#ff0000" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;

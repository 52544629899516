import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./components/Home";
import ErrorPage from "./components/ErrorPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Projects from "./components/Projects";
import Project from "./components/Project";
import Flat from "./components/Flat";
import Flats from "./components/Flats";
import Versions from "./components/Versions";
import About from "./components/About";
import App from "./App";
import "./App.scss";
import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ReactGA from "react-ga4";

ReactGA.initialize("G-KFWXRCMGL3");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "projects/",
    element: (
      <App>
        <Projects />
      </App>
    ),
  },
  {
    path: "projects/:id",
    element: (
      <App>
        <Project />
      </App>
    ),
  },
  {
    path: "flats/",
    element: (
      <App>
        <Flats />
      </App>
    ),
  },
  {
    path: "flats/:id",
    element: (
      <App>
        <Flat />
      </App>
    ),
  },
  {
    path: "versions/",
    element: (
      <App>
        <Versions />
      </App>
    ),
  },
  {
    path: "about/",
    element: (
      <App>
        <About />
      </App>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <div className="container mb-2">
      <div className="row">
        <div className="col-lg">
          <RouterProvider router={router} />
        </div>
      </div>
    </div>
  </React.StrictMode>
);
